const checkData = (data) => {
  const keys = Object.keys(data);
  let check = true;

  keys.forEach((key) => {
    if (data[key] === null || typeof data[key] === 'undefined' || data[key] === '' || data[key] === '-1') {
      check = false;
    }
  });
  return check;
};


export default checkData;
