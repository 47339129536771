import React from 'react';
import LoadingSpinner from './Modals/Loading/loadingSpinner';
import SvgSinglrCentre from './Icons/SinglrCentre';

const AppLoadingPanel = ({ animate }) => {
    return <div  
      style={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        animation: animate ? 'popUp-fadeIn 0.5s ease-in-out' : "",
      }}>
      <SvgSinglrCentre width="200px" height="200px" fill='white' />
      <LoadingSpinner size="100px" height="100px" color='white' />
    </div>
}

export default AppLoadingPanel;

