import React from 'react';
import PropTypes from 'prop-types';
// import { css } from '@emotion/react';
import { SpinnerInfinity } from 'spinners-react';


// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

const LoadingSpinner = ({ color, secondaryColor, containerCSS, size}) => (
  <div className="loading-spinner-container" style={containerCSS}>
    <SpinnerInfinity enabled={true} сolor={"#ffffff"} secondaryColor={secondaryColor} size={size} style={{ color }}/>
    {/* <BarLoader color={color} loading={loading} css={override} width={width} height={height} size={150} /> */}
  </div>
);

LoadingSpinner.propTypes = {
  color: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoadingSpinner;
