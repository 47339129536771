import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import mobile from 'is-mobile';
const Panel = lazy(() => import('./Pages/Panel/Panel'));
import isLoggedIn from './Pages/Panel/Procedures/isLoggedIn';
import AppLoadingPanel from './AppLoadingPanel';

const contentNode = document.getElementById('contents');

export default class App extends React.Component {
  state = {
    mouseDown: false,
    clickPos: [0, 0],
    curMousePos: [0, 0],
    mouseOnScreen: false,
    devMode: false,
    loading: true,
    idNumber: -1,
    loggedIn: false,
    profileUrl: "",
    admin: false,
    profileCreated: false,
    emailVerified: false, usedTempPass: false,
  }


  componentDidMount() {
    isLoggedIn(this.checkLoginSuccess, this.checkLoginFailure);
    this.setState({ isMobile: mobile() });
  }


  checkLoginSuccess = ({ idNumber, lastLogin, usedTempPass, profileUrl, admin, emailVerified }) => {
    // console.log("isAdmin: " + admin);
    this.setState({ loading: false, idNumber, loggedIn: true, profileUrl, admin, profileCreated: profileUrl ? true : false, emailVerified, usedTempPass });
  }

  checkLoginFailure = (res) => {
    const { error } = res;
    if (error) {
      console.log(error);
    }
    this.setState({ loggedIn: false, loading: false, });
  }

  onSuccess = ({ idNumber, lastLogin, usedTempPass, profileUrl, admin, emailVerified }) => {
    this.setState({
      loggedIn: true,
      idNumber: idNumber,
      profileCreated: profileUrl ? true : false,
      profileUrl: profileUrl,
      usedTempPass: usedTempPass,
      currentPanel: 5,
      currentSidePage: [0, 0, 0, 0, usedTempPass ? 2 : 0, 0, 0],
      loading: false,
      admin,
    });
  }
  appReset = () => {
    // loading: true,
    this.setState({
      idNumber: -1,
      loggedIn: false,
      profileUrl: "",
      admin: false,
      profileCreated: false,
      emailVerified: false, usedTempPass: false,
    });
  }

  setProfileCreated = () => {
    this.setState({ profileCreated: true });
  }

  render() {
    const {
      devMode, isMobile,
      idNumber, loggedIn,
      admin, profileCreated,
      emailVerified, usedTempPass,
      profileUrl, loading,
    } = this.state;

    return (
      <div
        role="presentation"
        className="fullPage"
      >
        {loading ?
          <AppLoadingPanel animate={true} />
          : <Suspense fallback={
            <AppLoadingPanel animate={false} />
          }><Panel
              devMode={devMode}
              isMobile={isMobile}
              idNumber={idNumber}
              loggedIn={loggedIn}
              profileUrl={profileUrl}
              admin={admin}
              profileCreated={profileCreated}
              emailVerified={emailVerified}
              usedTempPass={usedTempPass}
              onSuccess={this.onSuccess}
              appReset={this.appReset}
              setProfileCreated={this.setProfileCreated}
            />
          </Suspense>

        }
      </div>
    );
  }
}

ReactDOM.render(<App />, contentNode);
