import makeRequest from '../../../Engine/Requests/makeRequest';


const isLoggedIn = (success, failure, timeout) => {
  makeRequest('GET', 'auth', {}, (res) => {
    if (res.data) {
      console.log("checking isLoggedIn results: ");
      console.log(res.data);
      const { idNumber, lastLogin } = res.data;
      if ((typeof idNumber === 'number' || typeof idNumber === 'string') && typeof lastLogin === 'number') {
        success(res.data);
      } else {
        failure(`Received ID or loggedIn number is invalid: ${idNumber}`);
      }
    } else {
      failure('Checking login failed... no response data!');
    }
  }, failure, timeout, 'isLoggedIn');
};

export default isLoggedIn;
