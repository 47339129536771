import axios from 'axios';

import checkData from './checkData';

const expectedResponseProperties = ['error', 'displayMessage', 'context', 'code'];
const TIMEOUT = 15000;

/**
 * Make Request to server!
 * @param {string} type
 * @param {string} url
 * @param {object} data
 * @param {function} success
 * @param {function} fail
 * @param {function} timeout
 * @param {string} caller
 * @param {object} _headers
 * @param {object} params
 * @returns
 */
const makeRequest = (
  type, url, data, success, fail, timeout, caller, _headers, params, // Maybe add this to
) => {
  if (type === 'POST' || type === 'GET') {
    // Check url is a string
    if (typeof url !== 'string' || url === null) {
      fail({
        error: 'Address is either not a string or null!',
        data: {
          url,
        },
      });
      return;
    }

    // Check success, fail and timeout
    // First, timeout isn't critical.
    if (typeof success !== 'function' || typeof fail !== 'function' /* || typeof timeout !== 'function' */) {
      fail({
        error: 'One of the request fallbacks is not a function!',
        data: {
          success: typeof success,
          fail: typeof fail,
          timeout: typeof timeout,
        },
      });
      return;
    }

    // Check data is valid
    if (!checkData(data)) {
      fail({
        error: 'One of the items of data was null, undefined, empty, or -1',
        data,
      });
      return;
    }
    const fileHeaders = {
      'Content-Type': 'multipart/form-data',
    };
    const typicalHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };
    // Perform post
    axios({
      method: type,
      headers: _headers ? fileHeaders : typicalHeaders,
      params,
      url,
      data,
      timeout: TIMEOUT,

    }).then((res) => {
    // Responses should always contain the following:
    // error,
    // context,
    // displayMessage,
    // code,
      const check = true;
      // const keys = Object.keys(res.data);
      // expectedResponseProperties.forEach((prop) => {
      //   if (!keys.includes(prop)) check = false;
      // });
      if (check) {
        if (res.data.error !== null) {
          // Server is reporting an error
          fail(
            res.data,
          );
        } else {
        // Server responded and no error
          success(res.data);
        }
      } else {
      // Response check failed! Invalid response.
        fail({
          error: 'Response check failed! Invalid response format!',
          data: {
            type,
          },
        });
      }
    }).catch((error) => {
      fail({
        error,
      });
    });
  } else {
    // Check type
    fail({
      error: 'Request type not specified or incorrect!',
      data: {
        type,
      },
    });
  }
};


export default makeRequest;
